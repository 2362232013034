import isPropValid from "@emotion/is-prop-valid"
import { Link } from "gatsby"
import { HTMLProps } from "react"
import { WiDirectionUp } from "react-icons/wi"
import {
	LazyLoadImage,
	LazyLoadImageProps,
} from "react-lazy-load-image-component"
import { TTheme } from "../../types"
import mq from "../theme/mq"
import styled from "../theme/styled"
import { H4 } from "./headings"
import { Typography } from "./typography"

interface NavigaionBlockProps extends HTMLProps<HTMLDivElement> {
	linkTo: string
	title: string
	description: string
	imageSrc: string
	aspectRatio: number
}

const NavigationBlockLink = styled(H4)(({ theme }) => ({
	marginBottom: theme.spacing.m,
	textTransform: `uppercase`,
	textDecoration: `none`,
	textAlign: `center`,
	display: `flex`,
	width: `100%`,
	justifyContent: `center`,
	alignItems: `center`,
	svg: {
		transform: `rotate(90deg)`,
		display: `block`,
		width: `1em`,
		height: `1em`,
		flexShrink: 0,
		transition: `transform 0.4s ease`,
		marginLeft: theme.spacing.xxs,
	},
	":hover svg": {
		transition: `transform 0.1s ease`,
		transform: `rotate(90deg) translateY(-5px)`,
	},
})).withComponent(Link)

const NavigationBlockTypography = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing.m,
	marginBottom: theme.spacing.xxs,
	textAlign: `center`,
}))

NavigationBlockTypography.defaultProps = {
	size: `xxs`,
}

const NavigationBlockImg = (props: LazyLoadImageProps) => (
	<LazyLoadImage
		width={1}
		height={1}
		{...props}
		css={{
			width: `100%`,
			height: `auto`,
		}}
	/>
)

const NavigationBlockImgLink = styled(Link, {
	shouldForwardProp: isPropValid,
})<{ aspectRatio: number }>(({ aspectRatio }) => ({
	display: `block`,
	position: `relative`,
	overflow: `hidden`,
	width: `100%`,
	height: 0,
	paddingBottom: `${100 / aspectRatio}%`,
	img: {
		willChange: `transform`,
		transition: `transform 0.4s ease`,
		transform: `scale(1)`,
		position: `absolute`,
		top: 0,
		left: 0,
		width: `100%`,
		height: `100%`,
		objectFit: `cover`,
	},
	":hover img": {
		transition: `transform 0.1s ease`,
		transform: `scale(1.05)`,
	},
}))

export const NavigationBlock = ({
	linkTo,
	title,
	description,
	imageSrc,
	aspectRatio,
	...rest
}: NavigaionBlockProps) => {
	return (
		<div {...rest}>
			<NavigationBlockImgLink to={linkTo} aspectRatio={aspectRatio}>
				<NavigationBlockImg src={imageSrc} alt={title} />
			</NavigationBlockImgLink>
			<NavigationBlockTypography
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.m,
				})}
			>
				{description}
			</NavigationBlockTypography>
			<NavigationBlockLink to={linkTo}>
				{title} <WiDirectionUp />
			</NavigationBlockLink>
		</div>
	)
}

export const NavigationBlockSecondary = ({
	linkTo,
	title,
	description,
	imageSrc,
	aspectRatio,
	...rest
}: NavigaionBlockProps) => {
	return (
		<div {...rest} css={{ display: `flex`, flexWrap: `wrap` }}>
			<NavigationBlockTypography
				css={{
					flex: `1 0 100%`,
					order: 2,
					[mq.large]: {
						order: 1,
					},
				}}
			>
				{description}
			</NavigationBlockTypography>
			<NavigationBlockLink
				to={linkTo}
				css={{
					flex: `1 0 100%`,
					order: 3,
					[mq.large]: {
						marginTop: 0,
						order: 2,
					},
				}}
			>
				{title} <WiDirectionUp />
			</NavigationBlockLink>
			<NavigationBlockImgLink
				to={linkTo}
				aspectRatio={aspectRatio}
				css={{
					flex: `1 0 100%`,
					order: 1,
					[mq.large]: {
						order: 3,
					},
				}}
			>
				<NavigationBlockImg src={imageSrc} alt={title} />
			</NavigationBlockImgLink>
		</div>
	)
}
