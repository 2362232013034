import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { TTheme } from "../../types"
import { Container } from "../components/container"
import { HeroImage } from "../components/hero-image"
import {
	NavigationBlock,
	NavigationBlockSecondary,
} from "../components/navigation-block"
import { Section } from "../components/section"
import { generateSrcSet, getAspectRatio, imageUrlFor } from "../lib/image"
import mq from "../theme/mq"

export default function IndexPage() {
	const {
		homePage: {
			nodes: [data],
		},
	} = useStaticQuery<any>(graphql`
		query IndexPage {
			homePage: allSanityHomePage(filter: { _id: { eq: "homePage" } }) {
				nodes {
					bannerTitle
					bannerDescription
					bannerButtonTitle
					bannerButton
					image {
						...HeroImageFragment
					}
					navigationLinks {
						leftLink {
							...BlockFragment
						}
						rightLink {
							...BlockFragment
						}
						middleLink {
							...BlockFragment
						}
					}
					informationLinks {
						leftLink {
							...BlockFragment
						}
						rightLink {
							...BlockFragment
						}
					}
				}
			}
		}
	`)

	const {
		bannerTitle,
		bannerDescription,
		bannerButtonTitle,
		bannerButton,
		image,
		navigationLinks,
		informationLinks,
	} = data

	const imageBuilder = imageUrlFor(image).fit("crop")
	const imageUrl = imageBuilder.url()
	const imageSrcSet = generateSrcSet(
		imageBuilder,
		image.asset.fluid.sizes.split(" ")
	)

	return (
		<>
			<HeroImage
				imageAspectRatio={
					getAspectRatio(
						image.crop,
						image.asset.metadata.dimensions.width,
						image.asset.metadata.dimensions.height
					) || image.asset.fluid.aspectRatio
				}
				imageSrc={imageUrl || image.asset.fluid.src}
				imageSrcSet={imageSrcSet || image.asset.fluid.srcSet}
				imageBackgroundColor={
					image.asset.metadata?.palette?.dominant?.background
				}
				title={bannerTitle}
				description={bannerDescription}
				linkTo={bannerButton}
				linkTitle={bannerButtonTitle}
			/>
			<Container
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.xxxxl,
				})}
			>
				<div
					css={{
						display: `grid`,
						gridTemplateColumns: `1fr`,
						gridRowGap: `40px`,
						gridTemplateAreas: `
							"one "
							"two"
							"three"
						`,
						[mq.medium]: {
							gridGap: `40px`,
							justifyContent: `space-between`,
							gridTemplateColumns: `repeat(5, 1fr)`,
							gridTemplateAreas: `
								"one one one . ."
								". . two two two"
								"three three three . ."
							`,
						},
						[mq.large]: {
							gridTemplateColumns: `repeat(3, 1fr)`,
							gridTemplateAreas: `"one two three"`,
						},
					}}
				>
					<NavigationBlock
						linkTo={navigationLinks.leftLink.link}
						imageSrc={
							navigationLinks.leftLink.image.asset.fluid.src
						}
						title={navigationLinks.leftLink.linkTitle}
						description={navigationLinks.leftLink.description}
						aspectRatio={438 / 563}
						css={{ gridArea: `one` }}
					/>
					<NavigationBlockSecondary
						linkTo={navigationLinks.middleLink.link}
						imageSrc={
							navigationLinks.middleLink.image.asset.fluid.src
						}
						title={navigationLinks.middleLink.linkTitle}
						description={navigationLinks.middleLink.description}
						aspectRatio={438 / 563}
						css={{ gridArea: `two` }}
					/>
					<NavigationBlock
						linkTo={navigationLinks.rightLink.link}
						imageSrc={
							navigationLinks.rightLink.image.asset.fluid.src
						}
						title={navigationLinks.rightLink.linkTitle}
						description={navigationLinks.rightLink.description}
						aspectRatio={438 / 563}
						css={{ gridArea: `three` }}
					/>
				</div>
			</Container>
			<Container
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.xxxxl,
					marginBottom: theme.spacing.xxxl,
				})}
			>
				<Section>
					<div
						css={(theme: TTheme) => ({
							margin: `${theme.spacing.xxl} auto 0`,
							display: `grid`,
							gridTemplateColumns: `1fr`,
							justifyContent: `space-between`,
							gridRowGap: `40px`,
							[mq.medium]: {
								gridTemplateColumns: `repeat(2, 45%)`,
								gridGap: `40px`,
								display: `grid`,
								maxWidth: `100%`,
								gridRowGap: `0`,
								margin: `${theme.spacing.xxxl} auto`,
							},
							[mq.large]: {
								maxWidth: `90%`,
							},
							[mq.xlarge]: {
								maxWidth: `80%`,
							},
							[mq.xxlarge]: {
								maxWidth: `70%`,
							},
						})}
					>
						<NavigationBlock
							linkTo={informationLinks.leftLink.link}
							imageSrc={
								informationLinks.leftLink.image.asset.fluid.src
							}
							title={informationLinks.leftLink.linkTitle}
							description={informationLinks.leftLink.description}
							aspectRatio={492 / 328}
						/>
						<NavigationBlock
							linkTo={informationLinks.rightLink.link}
							imageSrc={
								informationLinks.rightLink.image.asset.fluid.src
							}
							title={informationLinks.rightLink.linkTitle}
							description={informationLinks.rightLink.description}
							aspectRatio={492 / 328}
						/>
					</div>
				</Section>
			</Container>
		</>
	)
}
