import { H4 } from "./headings"
import { HTMLProps } from "react"
import { TTheme } from "../../types"

interface SectionProps extends HTMLProps<HTMLDivElement> {
	title?: string
}

export const Section = ({ children, title }: SectionProps) => {
	return (
		<div>
			{title && (
				<div
					css={{
						display: `flex`,
						alignItems: `center`,
						justifyContent: `center`,
					}}
				>
					<H4>{title}</H4>
				</div>
			)}
			<div
				css={(theme: TTheme) => ({
					padding: `${theme.spacing.xxl} ${theme.spacing.l}`,
				})}
			>
				{children}
			</div>
		</div>
	)
}
